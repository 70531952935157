import { useRouter } from 'next/router';
import styles from './Page404.module.scss';
import { Button } from '@/components/Button';
import { SettingsType, Variants } from '@/types';
import { fetchData } from '@/services/fetchData';
import { useEffect, useState } from 'react';
import { Loader } from '@/components/Loader/Loader';

type Page404Translations = {
  title: string;
  desc: string;
  homeButton: string;
  backButton: string;
};
import { Layout } from '@/layout';
import { EMPTY_META } from '@/constants';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';
import { useDispatch } from 'react-redux';
import { setSearchActive } from '@/store';

const Page404 = () => {
  const router = useRouter();
  const [locales, setLocales] = useState<Page404Translations | null>(null);
  const [settings, setSettings] = useState<SettingsType | null>();
  const { isTablet } = useAdaptiveWidth();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchTranslations = async () => {
      const menu: SettingsType = await fetchData({
        url: `/settings/view`,
        locale: router.locale,
      });

      setLocales(menu.translations['/404']);
      setSettings(menu);
    };

    fetchTranslations();
  }, [router.locale]);

  useEffect(() => {
    if (!isTablet && typeof isTablet !== 'undefined') {
      dispatch(setSearchActive(true));
    }
  }, [isTablet, dispatch]);

  if (!locales || !settings) return <Loader fullScreen />;

  return (
    <Layout meta={EMPTY_META} data={settings}>
      <div className={styles.wrapper}>
        <img className={styles.dreamchik} src="/png/dm-stunt.png" alt="404" />
        <p className={styles.title}>{locales.title}</p>
        <p className={styles.desc}>{locales.desc}</p>
        <div className={styles.buttons}>
          <Button
            text={locales.homeButton}
            variant={Variants.Ghost}
            onClick={() => router.push('/')}
            className={styles.main}
          />
          {/* <Button
        text={locales.backButton}
        variant={Variants.Ghost}
        onClick={() => router.back()}
      /> */}
        </div>
      </div>
    </Layout>
  );
};

export default Page404;
